

//Links
a{
	text-decoration: none;
}


.btn{
	letter-spacing: 0.05rem;
}