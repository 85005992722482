.primary-header{
	background: $cyan;
}

.section-heading{
	background: $cyan;
}

.back-link{
	width: max-content;
	color: $medium-gray;
}

a{
	text-decoration: none !important;
}