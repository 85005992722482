.single-visit{
	.primary-header{
		padding: 90px 85px;

		h1{
			font-size: 30px;
			margin: 5px 0 0;

		}

		textarea{
			height: 40px;
		}

		.back-link{
			margin-top: -50px;
			font-family: $sonder;
		}

		.btn{
			padding: 0 30px;
		}


	}

	.content{
		padding: 50px;
		background: $white;
		
		p{
			color: $medium-gray;
		}

		strong{
			color: $primary;
		}

		h2{
			font-size: 20px;
			padding: 12px 10px 10px;
		}

		hr{
			color: $medium-gray;
		}
	}
}