$table-striped-bg: rgba($cyan, .5);

.table-container{
	padding: 0 20px;

	h3{
		margin: 20px 0 0;
	}

	thead{
	th{
		color: $primary;
		font-family: $sonder;
	}
}
}