//Colors
$blue:    #2e4a81;
//$indigo:  #6610f2;
//$purple:  #6f42c1;
$pink:    #e88c8d;
//$red:     #dc3545;
//$orange:  #fd7e14;
$yellow:  #fbd180;
//$green:   #198754;
//$teal:    #20c997;
$cyan:    #dbf4f7;
$black:	  #000000;
$white:   #ffffff;
$medium-gray: #8f8f8f;

$primary:       $blue;
$secondary:     $pink;
//$success:       $green;
//$info:          $cyan;
//$warning:       $yellow;
//$danger:        $red;
//$light:         $gray-100;
//$dark:          $gray-900;

// Body
$body-bg: #f8fafc;

// Typography
@font-face {
    font-family: 'sonder_slabblack';
    src: url('/fonts/sonder_slab_black_700-webfont.woff2') format('woff2'),
         url('/fonts/sonder_slab_black_700-webfont.woff') format('woff');
}
$sonder: 'sonder_slabblack';
$lato: 'lato';
$font-family-sans-serif: $lato, sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;



//Headings
//$headings-margin-bottom:      $spacer * .5;
$headings-font-family:        $sonder;
//$headings-font-style:         null;
$headings-font-weight:        bold;
//$headings-line-height:        1.2;
$headings-color:              $primary;


