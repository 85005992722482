.form{
	//padding: 50px 100px;
	label{
		font-family: $sonder;
		color: $primary;
	}
	input, select{
		font-family: $lato;
		font-weight: bold;
		color: rgba($black, 0.5);
	}
	.btn{
		width: max-content;
		font-family: $sonder;
	}
}
.medium-form{
	max-width: 600px;
	padding: 100px;
}

.full-height{
	height: 85vh;
}

$input-bg: $cyan;