.login{
	height: 100vh;

	.container{
		max-width: 800px;
	}
	header{
		background: $blue;
	}
	.form{
		background: $pink;
		padding: 50px 30px;

		h1{
			text-align: center;
			font-weight: bold;
			color: $white;
		}
		label{
			text-transform: uppercase;
			color: $white;
		}
		input{
			margin-bottom: 20px;
		}
	}
}