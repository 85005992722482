.navbar{
	padding-bottom: 30px !important;
	.nav-link{
		color: $cyan;
		font-size: 18px;
		letter-spacing: 1px;
		text-transform: uppercase;
		font-family: $sonder;
		padding: 8px 16px;

		&:hover, &.btn-secondary{
			color: $white;
		}
	}

	//Logo
	.navbar-brand{
		align-items: center;

		img{
			width: 165px;
		}

		span{
			color: $white;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 19px;
			line-height: 19px;
			font-family: $sonder;
			margin-left: 15px;
		}
	}
}

.nav-auth-links{
	padding-top: 10px;
	a{
		color: $white;
		text-decoration: none;
		outline: none;
		margin-top: 10px;

		&:hover{
			color: $white;
			background: transparent;
		}
	}
}