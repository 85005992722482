.client{
	.filter-bar{
		background: $cyan;
		padding: 50px;

		form{
			padding: 0;
		}
		select, input[type=text]{
			//bg white
		}
		select, input, a{
			margin-right: 20px;
			width: max-content;
			max-width: max-content;
		}
		a{
			align-self: flex-end;
		}
	}
}

.single-client{

	.case-info{
		header{
			background: $yellow;
			padding: 35px;
		}
		h4, p{
			color: $primary;
			font-size: 18px;
			width:max-content;
			margin: 0;
			
			span{
				font-family: $lato;
			}
		}
		
		.meta{
			padding: 15px 35px 0;
			p{
				display: block;
			}
		}

	}

	.visits, .parents, .children, .client-stats{
		padding: 35px 35px 0;
	}

	.client-stats{
		padding-bottom: 50px;
	}
}


